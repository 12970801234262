import React, { useState, useEffect } from "react";
import "./index.scss";
import Aside from "components/Sidebar";
import { useToaster } from "rsuite";
import ToastComponent from "components/ToastComponent";

import {
  Content,
  Container,
  Heading,
  Loader,
  Pagination,
  Table,
  Text,
  List,
} from "rsuite";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import PriceWeightItem from "./item";
function PricesPage() {
  const [prices, setPrices] = useState([]);
  const [weights, setWeights] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPrices();
    getWeights();
  }, []);
  const toaster = useToaster();

  const getPrices = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/price-settings`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setPrices(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getWeights = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/weight-settings`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setWeights(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const setPrice = (k, v) => {
    setLoading(true);
    Axios.put(`${API_URL}/admin/price-settings`, {
      setting: k,
      value: v,
    })
      .then(function (response) {
        ToastComponent("success", t("common.successful_updated"), toaster);
        getPrices();
        // setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const setWeight = (k, v) => {
    setLoading(true);
    Axios.put(`${API_URL}/admin/weight-settings`, {
      setting: k,
      value: v,
    })
      .then(function (response) {
        ToastComponent("success", t("common.successful_updated"), toaster);

        getWeights();
        // setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Container>
        <Aside />
        <Content>
          {loading && <Loader center backgrop size="lg" />}

          <div className="box-block">
            <Heading level={4} className="">
              {t("menu.prices_weights")} <br />
              <br />
            </Heading>

            <div className="d-flex justify-content-between">
              {weights?.length > 0 && (
                <div style={{ width: "100%", maxWidth: "calc(50% - 15px)" }}>
                  <Heading level={6} className="">
                    {t("menu.weights")}
                    <br /> <br />
                  </Heading>
                  <List
                    style={{ maxWidth: "100%", width: "100%" }}
                    bordered
                    hover
                  >
                    {weights.map((el) => {
                      return (
                        <PriceWeightItem
                          value={el.value}
                          setting={el.setting}
                          callback={setWeight}
                          loading={loading}
                        />
                      );
                    })}
                  </List>
                </div>
              )}
              {prices?.length > 0 && (
                <div style={{ width: "100%", maxWidth: "calc(50% - 15px)" }}>
                  <Heading level={6} className="">
                    {t("menu.prices")}
                    <br /> <br />
                  </Heading>
                  <List
                    style={{ maxWidth: "100%", width: "100%" }}
                    bordered
                    hover
                  >
                    {prices.map((el) => {
                      return (
                        <PriceWeightItem
                          value={el.value}
                          setting={el.setting}
                          callback={setPrice}
                        />
                      );
                    })}
                  </List>
                </div>
              )}
            </div>
          </div>
        </Content>
      </Container>
    </>
  );
}

export default PricesPage;
