import AuthPage from "pages/AuthPage";
import MainPage from "pages/MainPage";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "utils/settings";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import StatsPage from "pages/StatsPage";
import ProvidersPage from "pages/ProvidersPage";
import SmsFormatPage from "pages/SmsFormatPage";
import PricesPage from "pages/PricesPage";
// import NotFound from "pages/NotFound";
function WithToken(props) {
  const { children } = props;
  if (!window.location.href.includes("auth") && localStorage.getItem("token")) {
    Axios.defaults.headers.common[
      "authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }
  return <>{children}</>;
}
function AppRouter() {
  const [authorized, setAuthorized] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    Axios.get(`${API_URL}/user`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(function (response) {
      if (response) {
      } else {
        setAuthorized(false);
      }
    });
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/auth">
          <AuthPage />
        </Route>
        <WithToken>
          <Route exact path="/">
            <MainPage authorized={authorized} />
          </Route>
          <Route exact path="/stats">
            <StatsPage authorized={authorized} />
          </Route>
          <Route exact path="/sms-format">
            <SmsFormatPage authorized={authorized} />
          </Route>
          <Route exact path="/prices">
            <PricesPage authorized={authorized} />
          </Route>
          <Route exact path="/providers">
            <ProvidersPage authorized={authorized} />
          </Route>
        </WithToken>
      </Switch>
    </Router>
  );
}

export default AppRouter;
