import React, { useState } from "react";
import { Input, InputGroup, InputNumber, List } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";

function PriceWeightItem(props) {
  const { value, setting, callback, loading } = props;
  const [v, setV] = useState(props.value);
  return (
    <List.Item key={setting} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span>{setting}</span>

        <InputGroup style={{ width: "140px" }}>
          <InputNumber
            decimalSeparator="."
            scrollable={false}
            style={{ width: "100px" }}
            onChange={(val) => setV(val)}
            value={v}
          />
          <InputGroup.Button
            disabled={v == props.value || loading}
            onClick={() => callback(setting, v)}
          >
            <EditIcon />
          </InputGroup.Button>
        </InputGroup>
      </div>
    </List.Item>
  );
}

export default PriceWeightItem;
