import React, { useState, useEffect } from "react";
import "./index.scss";
import "./index.dark.scss";
import { withTranslation } from "react-i18next";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useLocalStorage } from "utils/utils";
import { Sidebar, Sidenav, Navbar, Nav, SelectPicker } from "rsuite";
import { Detail } from "@rsuite/icons";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import BarChartIcon from "@rsuite/icons/BarChart";
import ExitIcon from "@rsuite/icons/Exit";
import TaskIcon from '@rsuite/icons/Task';
import MessageIcon from '@rsuite/icons/Message';
import TagNumberIcon from '@rsuite/icons/TagNumber';

function Aside({ t }) {
  const location = useLocation();
  const [locale, setLocale] = useLocalStorage("locale", "ru");
  const [expand, setExpand] = useState(true);
  const history = useHistory();
  const [locales, setLocales] = useState(locale);

  useEffect(() => {}, [locale]);
  const NavLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return (
      <Link to={href} as={as}>
        <a ref={ref} {...rest} />
      </Link>
    );
  });
  const menu = [
    {
      icon: <Detail />,
      label: t("menu.sms"),
      key: "sms",
      url: "/",
    },
    {
      icon: <BarChartIcon />,
      label: t("menu.stats"),
      key: "stats",
      url: "/stats",
    },
    {
      icon: <TaskIcon />,
      label: t("menu.providers"),
      key: "providers",
      url: "/providers",
    },
    {
      icon: <TagNumberIcon />,
      label: t("menu.prices_weights"),
      key: "prices",
      url: "/prices",
    },
    {
      icon: <MessageIcon />,
      label: t("menu.smsFormat"),
      key: "smsFormat",
      url: "/sms-format",
    },
    {
      icon: <ExitIcon />,
      label: t("common.logout"),
      type: "logout",
    },
  ];

  return (
    <Sidebar
      style={{ display: "flex", flexDirection: "column" }}
      width={expand ? 260 : 56}
      collapsible
    >
      <Sidenav expanded={expand}>
        <Sidenav.Body>
          <Nav>
            {menu.map((el) => {
              return (
                <Nav.Item
                  key={el.label}
                  icon={el.icon}
                  active={location.pathname == el.url}
                  onClick={() => {
                    if (el.type == "logout") {
                      localStorage.removeItem("token");
                      window.location.href = "/auth";
                      return;
                    }
                    history.push(el.url);
                  }}
                >
                  {el.label}
                </Nav.Item>
              );
            })}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <Navbar appearance="subtle" className="nav-toggle">
        <SelectPicker
          searchable={false}
          data={[
            { label: t("language.ru"), value: "ru" },
            { label: t("language.en"), value: "en" },
          ]}
          placement="top"
          style={{ width: "calc(100% - 20px)", marginBottom: "10px" }}
          value={locale}
          onChange={(value) => setLocale(value)}
          placeholder={t(`language.${locale}`)}
          cleanable={false}

        />
      </Navbar>
    </Sidebar>
  );
}

export default withTranslation()(Aside);
