export const ruTranslations = {
  "validation.mixed.required": "Поле обязательно для заполнения",
  "validation.mixed.oneOf:values": "Значение поля должно быть одним из values",
  "validation.string.min": "Количество символов должно быть не меньше min",
  "breadcrumb.partner": "Партнёр",
  "breadcrumb.partners": "Партнёры",
  "breadcrumb.devices": "Девайсы",
  "breadcrumb.operators": "Операторы",
  "breadcrumb.orders": "Заказы",
  "breadcrumb.payments": "Выплаты",
  "breadcrumb.profiles": "Профили",
  "breadcrumb.services": "Сервисы",
  "common.register": "Регистрация",
  "menu.providers": "Провайдеры",
  "tooltip.required": "Обязательное поле",
  "table.today": "Сегодня",
  "table.yesterday": "Вчера",
  "menu.smsFormat": "Форматирование СМС",
  "table.regex": "Регулярное выражение",
  "table.provider": "Провайдер",
  "table.contentExample": "Шаблон сообщения",
  "table.edit": "Редактировать",
  undefinedData: "Некорректные параметры",
  empty: "Заполните обязательные поля",
  "table.last7days": "Последние 7 дней",
  "table.total": "Всего",
  "table.pagination_pages": "Страниц",
  "table.go_to": "Перейти к",
  "menu.prices_weights": "Параметры цен и веса",
  "menu.prices": "Цены",
  "menu.weights": "Веса",
  "common.successful_updated": "Данные успешно обновлены",
  "breadcrumb.simcards": "Симкарты",
  "breadcrumb.simfilters": "Фильтры смс",
  "breadcrumb.transactions": "Транзакции",
  "common.username": "Имя пользователя",
  "common.email": "Email",
  "common.password": "Пароль",
  "common.registration": "Регистрация",
  "common.login": "Войти",
  "common.logout": "Выйти",
  "common.cancel": "Отмена",
  "common.gb": "Гб",
  "common.upload_file_csv": "Загрузите CSV файл",
  "common.id": "ID",
  "common.type": "Тип",
  "common.pu": "ПУ",
  "common.bandWidth": "Пропускная способность",
  "common.bandWidthLimit": "Лимит пропускной способности",
  "common.createdAt": "Создано",
  "common.valid": "Дейстующий",
  "common.state": "Состояние",
  "common.action": "Действие",
  "common.submit": "Отправить",
  "common.file_success": "Файл успешно загружен",
  "common.file_failed": "Произошла ошибка",
  "common.notifications.fetch_data_error": "Не удалось получить данные",
  "common.notifications.fetch_create_error": "Произошла ошибка при создании",
  "common.notifications.fetch_edit_error":
    "Произошла ошибка при редактировании",
  "common.notifications.fetch_delete_error": "Произошла ошибка при удалении",
  "common.notifications.fetch_create_success": "Элемент успешно создан",
  "common.notifications.fetch_edit_success": "Элемент успешно отредактирован",
  "common.notifications.fetch_delete_success": "Элемент успешно удалён",
  "common.confirmation.delete_body": "Подтвердите действие удаления",
  "common.confirmation.delete_title": "Внимание!",
  "devices.not_found": "Девайсы не найдены",
  "devices.show_all": "Показать все девайсы",
  "login_page.title": "Вход в систему",
  "login_page.subtitle": "Войдите в свой аккаунт",
  "front_page.body.login": "Войти",
  "front_page.body.register": "Зарегистрироваться",
  "front_page.registration.title": "Регистрация",
  "front_page.registration.communication_type": "Тип коммуникации",
  "front_page.registration.communication": "Ссылка для коммуникации",
  "front_page.registration.message": "Сообщение",
  "services.header.title": "Сервисы",
  "services.common.internal_name": "Название",
  "services.common.display_priority": "Приоритет",
  "services.common.forwardable": "Переадресация",
  "services.common.max_reports": "Лимит репортов",
  "services.common.used_flush_delay": "Период сброса ЧС",
  "services.common.reports_flush_delay": "Период использования",
  "services.common.blacklist_flush_delay": "Период сброса R",
  "services.form.formats": "Форматы",
  "services.form.algorithm": "Алгоритм",
  "services.form.algorithm_placeholder": "Все",
  "services.form.logo_button": "Выберете изображение",
  "services.form.logo_helper_text":
    "Размер изображения должен быть не больше 200x200 пикселей",
  "services.edit_modal.title": "Редактирование сервиса",
  "services.create_modal.title": "Новый сервис",
  "operators.header.title": "Операторы",
  "operators.table.added": "Добавлен",
  "operators.common.operator": "Оператор",
  "operators.common.country": "Страна",
  "operators.common.iccid_prefix": "Iccid префикс",
  "operators.common.ussd_number": "Ussd номера",
  "operators.common.ussd_new_sim": "Ussd новой сим",
  "operators.common.forwardable": "Переадресация",
  "operators.common.logo": "Logo",
  "operators.edit_form.title": "Редактирование оператора",
  "operators.create_form.title": "Новый оператор",
  "partners.header.title": "Партнеры",
  "partners.actions.add": "Добавить",
  "partners.table.details_payments": "Выплаты",
  "partners.table.details_price": "Прайс",
  "partners.table.details_transactions": "Транзакции",
  "partners.table.details_licenses": "Лицензии",
  "partners.table.details_statistics": "Статистика",
  "partners.common.username": "Имя",
  "partners.common.email": "Email",
  "partners.common.balance": "Баланс",
  "partners.common.balance_threshold": "Порог баланса",
  "partners.common.profile": "Профиль",
  "partners.common.password": "Пароль",
  "partners.common.license_limit": "Лимит лицензий",
  "partners.common.is_simbank_connected": "Подключение симбанокв",
  "partners.common.is_ussd_enabled": "Ussd команды",
  "partners.common.access_sms": "Просмотр всех смс",
  "partners.common.payout_types": "Разрешенные способы выплат",
  "partners.common.enabled": "Активен",
  "partners.create_modal.title": "Создание партнёра",
  "partners.partner_disable_confirmation.title": "Внимание!",
  "partners.partner_disable_confirmation.body":
    "Вы действительно хотите отключить партнёра?",
  "licenses.header.title": "Лицензии",
  "licenses.limit.title": "Лимит лицензий",
  "licenses.edit_modal.title": "Редактирование лицензии",
  "licenses.create_modal.title": "Добавление лицензии",
  "licenses.create": "Создать новую",
  "licenses.table.license": "Хеш",
  "licenses.table.devices": "Устройства",
  "licenses.table.is_online": "Онлайн",
  "licenses.table.ping_at": "Последний ответ",
  "licenses.table.os_type": "Тип ОС",
  "licenses.table.smaster_version": "Версия Smaster",
  "licenses.common.delayed_scanning_port": "ЗСП",
  "licenses.common.description": "Описание",
  "licenses.common.scanning_port_threshold": "ПСП",
  "licenses.common.port_prefix": "ПП",
  "licenses.common.is_extended_log": "Расширенный лог",
  "licenses.common.period_clear_log": "Период отчистки лога",
  "licenses.common.is_enabled": "Активен",
  "licenses.common.active_until": "Срок действия лицензии",
  "license.delete": "Удалить",
  "menu.sms": "Главная",
  "menu.pay": "Пополнить баланс",
  "heading.total_orders": "Всего заказов",
  "heading.finished": "Завершено заказов",
  "menu.stats": "Статистика",
  "balance.payment_success": "Оплата прошла успешно",
  "menu.history": "История баланса",
  "menu.settings": "Настройки аккаунта",
  "menu.apikey": "Ваш Api-ключ: ",
  "language.ru": "Русский",
  "language.en": "Английский",
  "helmet.title": "Виртуальные номера для приема смс онлайн",
  "helmet.description":
    "Одноразовые номера для регистрации более чем на 600 сервисах",
  profile: "Профиль",
  "heading.personal": "Личные данные",
  "heading.subAccounts": "Пользователи",
  "placeholder.email": "E-mail",
  "placeholder.url": "Url",
  "placeholder.username": "Имя",
  "placeholder.balance_threshold": "Порог баланса, руб",
  "placeholder.os_type": "Тип ОС",
  "placeholder.description": "Описание",
  "placeholder.scanning_port_threshold": "Порог сканирования портов",
  "placeholder.delayed_scanning_port": "Задержка сканирования портов",
  "placeholder.period_clear_log": "Период отчистки лога",
  "placeholder.port_prefix": "Префикс порта",
  "placeholder.active_until": "Срок действия лицензии",
  "placeholder.is_extended_log": "Расширенный лог",
  "placeholder.is_enabled": "Активен",
  "modal.cancel": "Закрыть",
  "modal.create": "Создать",
  "form.update_data": "Обновить данные",
  "confirm.agree": "Подтвердите действие",
  "confirm.update_profile": "Вы уверены, что хотите обновить данные профиля?",
  "confirm.delete_rodos": "Вы уверены, что хотите удалить Родос?",
  "confirm.delete_iccid": "Вы уверены, что хотите удалить ICCID?",
  "confirm.confirm": "Подтвердить",
  "confirm.cancel": "Отменить",
  "login.title": "Вход в систему",
  "login.subtitle": "Войдите в свой аккаунт",
  simbanks: "Симбанки",
  "simbanks.item.title": "Симбанк",
  "simbanks.item.update": "Обновить все сим",
  "simbanks.edit": "Конфигурация симбанка",
  "simbanks.partner": "Партнер",
  "simbanks.port": "Порт подключения",
  "simbanks.id": "ID симбанка",
  "simbanks.model": "Модель",
  "simbanks.number": "№ симбанка",
  "simbanks.license": "Лицензия",
  "simbanks.status.online": "Online",
  "simbanks.status.offline": "Offline",
  "simbanks.status.in_banks": "В симбанке",
  "simbanks.status.search": "Поиск сети",
  "simbanks.status.no_network": "Нет сети",
  "simbanks.status.error": "Ошибка получения Iccid",
  "iccid.title": "ICCID - привязки",
  "iccid.prefix": "Префикс",
  "common.actions": "Действия",
  "iccid.operator": "Оператор",
  "iccid.ussd": "USSD",
  "iccid.ussd_new": "USSD новой SIM",
  "iccid.country": "Страна",
  "iccid.forwarding": "Переадресация",
  "iccid.add": "Добавить ICCID",
  "iccid.edit": "Редактировать ICCID",
  "placeholder.prefix": "Префикс",
  "placeholder.actions": "Действия",
  "placeholder.operator": "Оператор",
  "placeholder.getnum_ussd": "USSD",
  "placeholder.newsim_ussd": "USSD новой SIM",
  "placeholder.country_id": "Страна",
  "placeholder.forwardable": "Переадресация",
  "placeholder.image": "Изображение",
  "rodos.title": "Настройки подключения Родос",
  "rodos.header.title": "Родос",
  "rodos.configuration": "Конфигурация Родос",
  "rodos.add": "Добавление Родос",
  "rodos.add.modal.title":
    "Конфигурация каналов, \n для удаления привязки введите '-' в поле устройства",
  "rodos.channel": "Канал",
  "rodos.device": "Устройство",
  "rodos.devices": "Устройства",
  "rodos.url": "Url доступа",
  "nav.main": "Главная",
  "nav.about": "О нас",
  "nav.work": "Как это работает?",
  "nav.services": "Сервисы",
  "nav.faq": "FAQ",
  "nav.api": "API",
  "menu.apikey_copy": "API-key скопирован",
  "nav.news": "Новости",
  "nav.profile": "Кабинет",
  "heading.tagline": "Ваш сервис для приема SMS онлайн",
  "heading.payments": "Ваши оплаты",
  "heading.order": "Ваши заказы",
  "heading.services": "Сервисы",
  "heading.subtitle":
    "SMS-online.pro — это полностью автоматизированный сервис для приема СМС онлайн \nЭто означает, что вам не понадобится мобильный телефон для подтверждения действий, активации аккаунтов в популярных сервисах \nВы пополняете номер счета и получаете текст сообщения СМС-сообщение на экране. \nНаш сайт смс-активации также предлагает вам арендовать личный виртуальный номер телефона. \nНа ваш выбор - более 5000 номеров из 15 стран мира",
  "heading.add_pay": "Пополнение баланса",
  "about.text":
    "Виртуальный телефонный номер онлайн — это возможность принимать сообщения независимо от того, в какой точке планеты вы находитесь. Главное — доступ в сеть.\nДля совершения всех необходимых действий в сети вам не понадобится настоящий мобильный телефон — сервис функционирует независимо от того, находитесь ли вы в зоне покрытия мобильной сети или нет.\nВсе, что от вас требуется — это пополнить счет своего виртуального номера. Вам не нужно специальное оборудование и sim-карта.",
  "about.subtitle": "Для чего нужен виртуальный телефон?",
  "about.list1":
    "Если вы совершаете регулярные рассылки, то вам просто необходимо иметь десятки телефонных номеров, чтобы сообщение гарантированно доходило до абонента.",
  "about.list2":
    "Подтверждайте регистрации в социальных сетях и прочих сервисах — теперь вам доступна регистрация множества аккаунтов на любых сайтах, которые можно использовать в своих целях.",
  "about.list3":
    "Вы можете использовать свой виртуальный номер в целях защиты от спама основного телефона при регистрации на подозрительных ресурсах",
  "about.subtext":
    "Основным преимуществом виртуального телефона можно считать его дешевизну. Получается, что у вас есть телефон, который вы можете использовать для разных целей — беспрепятсвенный полет вашей фантазии гарантирован.\n\nВаш личный телефонный номер онлайн — это гарантия того, что вы всегда будете на связи! Бывают ситуации, когда человек потерял телефон, забыл за него заплатить, случайно оставил дома, когда собирал вещи в поездку.\nВместе с нами — это больше не проблема! Все, что вам нужно — это доступ к сети интернет.",
  "work.subtitle":
    "Принимайте смс-сообщения на виртуальный номер с любых доступных сервисов",
  "work.text1": "Зарегистрируйте аккаунт в нашей системе",
  "work.text2": "Пополните баланс",
  "work.text3": "Получайте смс с любого сервиса",
  "services.subtitle": "Сервисы, с которых мы принимаем СМС на текущий момент",
  "table.service": "Сервис",
  "table.date": "Дата",
  "filter.datepicker": "Выберите дату",
  "table.status": "Статус",
  "table.statuses": "Статус",
  "table.amount": "Сумма",
  "table.type": "Тип",
  "table.all": "All",
  "table.waiting": "Ожидается",
  "table.approved": "Одобрено",
  "table.declined": "Отклонено",
  "table.canceled": "Отменено",
  "table.refill": "Пополнение счета",
  "table.waiting_phone": "Ожидание звонка",
  "table.waiting_sms": "Ожидание сообщения",
  "table.finished": "Заврешено",
  "table.balance_correction": "Коррекция баланса",
  "text.minCount": "Мин. сумма",
  "text.maxCount": "Макс. сумма",
  "text.alotof": "Много",
  "table.count": "Количество",
  "table.price": "Цена",
  "table.order_create": "Заказ успешно создан",
  "table.country": "Страна",
  "table.all": "Все",
  "table.not_found": "Не найдено",
  "table.dateFrom": "Дата с (ДД.ММ.ГГГГ)",
  "table.dateTo": "Дата до (ДД.ММ.ГГГГ)",
  "table.notFound": "Нет подходящих результатов",
  "world.europe": "Европа",
  "world.asia": "Азия",
  "world.africa": "Африка",
  "world.south_america": "Южная Америка",
  "world.north_america": "Северная Америка",
  "world.oceania": "Океания",
  "world.choose": "Выбрать страну",

  "country.af": "Афганистан",
  "country.al": "Албания",
  "country.aq": "Антарктида",
  "country.dz": "Алжир",
  "country.as": "Восточное Самоа",
  "country.ad": "Андорра",
  "country.ao": "Ангола",
  "country.ag": "Антигуа и Барбуда",
  "country.az": "Азербайджан",
  "country.ar": "Аргентина",
  "country.au": "Австралия",
  "country.at": "Австрия",
  "country.bs": "Багамы",
  "country.bh": "Бахрейн",
  "country.bd": "Бангладеш",
  "country.am": "Армения",
  "country.bb": "Барбадос",
  "country.be": "Бельгия",
  "country.bm": "Бермуды",
  "country.bt": "Бутан",
  "country.bo": "Боливия",
  "country.ba": "Босния и Герцеговина",
  "country.bw": "Ботсвана",
  "country.bv": "Буве",
  "country.br": "Бразилия",
  "country.bz": "Белиз",
  "country.io": "Британ. Террит.",
  "country.sb": "Соломоновы О-ва",
  "country.vg": "Виргин. О-ва, Британские",
  "country.bn": "Бруней",
  "country.bg": "Болгария",
  "country.mm": "Мьянма",
  "country.bi": "Бурунди",
  "country.by": "Беларусь",
  "country.kh": "Камбоджа",
  "country.cm": "Камерун",
  "country.ca": "Канада",
  "country.cv": "Кабо-верде",
  "country.ky": "Кайман",
  "country.cf": "Центр. - Афр. Республика",
  "country.lk": "Шри-ланка",
  "country.td": "Чад",
  "country.cl": "Чили",
  "country.cn": "Китай",
  "country.tw": "Тайвань",
  "country.cx": "О-в Рождества",
  "country.cc": "Кокосовые О-ва",
  "country.co": "Колумбия",
  "country.km": "Коморы",
  "country.yt": "Майотта",
  "country.cg": "Конго",
  "country.cd": "Конго",
  "country.ck": "О-ва Кука",
  "country.cr": "Коста-рика",
  "country.hr": "Хорватия",
  "country.cu": "Куба",
  "country.cy": "Кипр",
  "country.cz": "Чехия",
  "country.bj": "Бенин",
  "country.dk": "Дания",
  "country.dm": "Доминика",
  "country.do": "Доминиканская Республика",
  "country.ec": "Эквадор",
  "country.sv": "Эль-сальвадор",
  "country.gq": "Экваториальная Гвинея",
  "country.et": "Эфиопия",
  "country.er": "Эритрея",
  "country.ee": "Эстония",
  "country.fo": "Фарерские О-ва",
  "country.fk": "Фолклендские О-ва",
  "country.gs": "Южная Джорджия и Южные Сандвичевы Острова",
  "country.fj": "Фиджи",
  "country.fi": "Финляндия",
  "country.ax": "Эландские Острова",
  "country.fr": "Франция",
  "country.gf": "Гвиана",
  "country.pf": "Французская Полинезия",
  "country.tf": "Фр. Южные Территории",
  "country.dj": "Джибути",
  "country.ga": "Габон",
  "country.ge": "Грузия",
  "country.gm": "Гамбия",
  "country.ps": "Палестинская Территория, Оккупированная",
  "country.de": "Германия",
  "country.gh": "Гана",
  "country.gi": "Гибралтар",
  "country.ki": "Кирибати",
  "country.gr": "Греция",
  "country.gl": "Гренландия",
  "country.gd": "Гренада",
  "country.gp": "Гваделупа",
  "country.gu": "Гуам",
  "country.gt": "Гватемала",
  "country.gn": "Гвинея",
  "country.gy": "Гайана",
  "country.ht": "Гаити",
  "country.hm": "Херд и Макдональд",
  "country.va": "Ватикан",
  "country.hn": "Гондурас",
  "country.hk": "Гонконг",
  "country.hu": "Венгрия",
  "country.is": "Исландия",
  "country.in": "Индия",
  "country.id": "Индонезия",
  "country.ir": "Иран",
  "country.iq": "Ирак",
  "country.ie": "Ирландия",
  "country.il": "Израиль",
  "country.it": "Италия",
  "country.ci": "Кот Д'ивуар",
  "country.jm": "Ямайка",
  "country.jp": "Япония",
  "country.kz": "Казахстан",
  "country.jo": "Иордания",
  "country.ke": "Кения",
  "country.kp": "Корея [КНДР)",
  "country.kr": "Корея",
  "country.kw": "Кувейт",
  "country.kg": "Кыргызстан",
  "country.la": "Лаос",
  "country.lb": "Ливан",
  "country.ls": "Лесото",
  "country.lv": "Латвия",
  "country.lr": "Либерия",
  "country.ly": "Ливия",
  "country.li": "Лихтенштейн",
  "country.lt": "Литва",
  "country.lu": "Люксембург",
  "country.mo": "Макао",
  "country.mg": "Мадагаскар",
  "country.mw": "Малави",
  "country.my": "Малайзия",
  "country.mv": "Мальдивы",
  "country.ml": "Мали",
  "country.mt": "Мальта",
  "country.mq": "Мартиника",
  "country.mr": "Мавритания",
  "country.mu": "Маврикий",
  "country.mx": "Мексика",
  "country.mc": "Монако",
  "country.mn": "Монголия",
  "country.md": "Молдова",
  "country.me": "Черногория",
  "country.ms": "Монтсеррат",
  "country.ma": "Марокко",
  "country.mz": "Мозамбик",
  "country.om": "Оман",
  "country.na": "Намибия",
  "country.nr": "Науру",
  "country.np": "Непал",
  "country.nl": "Нидерланды",
  "country.an": "Нидерландские Антилы",
  "country.aw": "Аруба",
  "country.nc": "Новая Каледония",
  "country.vu": "Вануату",
  "country.nz": "Новая Зеландия",
  "country.ni": "Никарагуа",
  "country.ne": "Нигер",
  "country.ng": "Нигерия",
  "country.nu": "Ниуэ",
  "country.nf": "Норфолк",
  "country.no": "Норвегия",
  "country.mp": "Марианские Острова",
  "country.um": "Малые Тихоок. Острова (США)",
  "country.fm": "Микронезия",
  "country.mh": "Маршалловы Острова",
  "country.pw": "Палау",
  "country.pk": "Пакистан",
  "country.pa": "Панама",
  "country.pg": "Папуа - Новая Гвинея",
  "country.py": "Парагвай",
  "country.pe": "Перу",
  "country.ph": "Филиппины",
  "country.pn": "Питкэрн",
  "country.pl": "Польша",
  "country.pt": "Португалия",
  "country.gw": "Гвинея-бисау",
  "country.tp": "Восточный Тимор",
  "country.pr": "Пуэрто-рико",
  "country.qa": "Катар",
  "country.re": "Реюньон",
  "country.ro": "Румыния",
  "country.ru": "Россия",
  "country.rw": "Руанда",
  "country.sh": "Святая Елена",
  "country.kn": "Сент-китс и Невис",
  "country.ai": "Ангилья",
  "country.lc": "Сент-люсия",
  "country.pm": "Сен-пьер и Микелон",
  "country.vc": "Сент-винсент и Гренадины",
  "country.sm": "Сан-марино",
  "country.st": "Сан-томе и Принсипи",
  "country.sa": "Саудовская Аравия",
  "country.sn": "Сенегал",
  "country.rs": "Сербия",
  "country.sc": "Сейшелы",
  "country.sl": "Сьерра-леоне",
  "country.sg": "Сингапур",
  "country.sk": "Словакия",
  "country.vn": "Вьетнам",
  "country.si": "Словения",
  "country.so": "Сомали",
  "country.za": "Южная Африка",
  "country.zw": "Зимбабве",
  "country.es": "Испания",
  "country.eh": "Западная Сахара",
  "country.sd": "Судан",
  "country.sr": "Суринам",
  "country.tr": "Турция",
  "country.xy": "Случайная страна",
  "country.ua": "Украина",
  "country.gb": "Великобритания",
  "country.se": "Швеция",
  "country.ch": "Швейцария",
  "country.mk": "Македония",
  "country.ae": "Объединённые Арабские Эмираты",
  "country.gg": "Гернси",
  "country.je": "Джерси",
  "country.im": "о-в Мэн",
  "country.sj": "Шпицберген и Ян-Майен",
  "country.tm": "Туркменистан",
  "country.uz": "Узбекистан",
  "country.ye": "Йемен",
  "country.tl": "Восточный Тимор",
  "country.tj": "Таджикистан",
  "country.th": "Тайланд",
  "country.sy": "Сирия",
  "country.tn": "Тунис",
  "country.tg": "Того",
  "country.ug": "Уганда",
  "country.tz": "Танзания",
  "country.bf": "Буркина-Фасо",
  "country.zm": "Замбия",
  "country.sz": "Эсватини",
  "country.tc": "Острова Теркс и Кайкос",
  "country.tt": "Тринидад и Тобаго",
  "country.uy": "Уругвай",
  "country.vi": "Американские Виргинские острова",
  "country.ve": "Венесуэла",
  "country.tk": "Токелау",
  "country.us": "Соединенные штаты Америки",
  "country.to": "Тонга",
  "country.tv": "Тувалу",
  "country.wf": "о-ва Уоллис и Футуна",
  "country.ws": "Самоа",
  "country.eg": "Египет",
  "country.xy": "Случайная страна",
  "country.search": "Поиск по странам",
};

export const enTranslations = {
  "breadcrumb.devices": "Devices",
  "breadcrumb.main": "main",
  "breadcrumb.operators": "operators",
  "breadcrumb.orders": "orders",
  "breadcrumb.partner": "partner",
  "breadcrumb.partners": "partners",
  "breadcrumb.payments": "payments",
  "table.today": "Today",
  "menu.providers": "Providers",
  "table.yesterday": "Yesterday",
  "table.last7days": "Last 7 days",
  "table.total": "Total",
  "table.pagination_pages": "Page",
  "table.go_to": "Go to",
  "tooltip.required": "Required",
  "heading.finished": "Finished",
  "heading.total_orders": "Completed orders",
  "breadcrumb.profiles": "profiles",
  "breadcrumb.services": "services",
  "breadcrumb.simcards": "sim cards",
  "breadcrumb.simfilters": "sim filters",
  "breadcrumb.transactions": "transactions",
  "common.added": "added",
  "common.cancel": "Cancel",
  "common.countries": "countries",
  "common.id": "ID",
  "common.county": "county",
  "common.forwardable": "forwardable",
  "common.iccid_prefix": "iccid prefix",
  "common.login": "Login",
  "common.registration": "Registration",
  "common.username": "Username",
  "common.bandWidth": "Band width",
  "common.bandWidthLimit": "Band width limit",
  "common.createdAt": "Created at",
  "common.valid": "Is valid",
  "common.password": "Password",
  "common.logout": "log out",
  "common.upload_file_csv": "Upload CSV file",
  "common.type": "Type",
  "common.pu": "Partner control",
  "common.operator": "operator",
  "common.operators": "operators",
  "common.register": "Register",
  "common.ussd_new_sim": "ussd new sim",
  "common.ussd_number": "ussd number",
  "common.submit": "Submit",
  "devices.not_found": "Devices not found",
  "devices.show_all": "Show all devices",
  "partners.actions.add": "Add",
  "common.file_success": "File uploaded successfully",
  "menu.smsFormat": "SMS formatting",
  "table.regex": "RegEx",
  "table.provider": "Provider",
  "table.contentExample": "Message template",
  "table.edit": "Edit",
  undefinedData: "Incorrect parameters",
  empty: "Fill in the required fields",
  "common.file_failed": "An error has occurred",
  "licenses.header.title": "Licenses",
  "licenses.limit.title": "Licenses limit",
  "menu.prices_weights": "Price and weight parameters",
  "menu.prices": "Prices",
  "menu.weights": "Weights",
  "common.successful_updated": "Data successfully updated",
  "licenses.edit_modal.title": "Edit license",
  "licenses.create_modal.title": "Add license",
  "licenses.create": "Add new",
  "licenses.table.license": "Hash",
  "licenses.table.devices": "Devices",
  "licenses.table.is_online": "Online",
  "licenses.table.ping_at": "Last reply",
  "licenses.table.os_type": "OS Type",
  "licenses.table.smaster_version": "Smaster version",
  "licenses.common.delayed_scanning_port": "PSD",
  "licenses.common.description": "Description",
  "licenses.common.scanning_port_threshold": "SPT",
  "helmet.title": "Virtual numbers for receiving SMS online",
  "helmet.description":
    "Disposable numbers for registration on more than 600 services",
  "licenses.common.port_prefix": "PP",
  "licenses.common.is_extended_log": "Extended log",
  "licenses.common.period_clear_log": "Log cleaning period",
  "licenses.common.is_enabled": "Is enabled",
  "licenses.common.active_until": "License validity period",
  "license.delete": "Delete",
  "validation.country.not_found": "country not found",
  "validation.iccid.not_exist": "iccid id not exist",
  "validation.iccid.prefix_already_exist": "iccid prefix already exist",
  "validation.locale.not_found": "locale not found",
  "menu.sms": "Main",
  "menu.pay": "Add balance",
  "menu.stats": "Stats",
  "balance.payment_success": "Payment was successful",
  "menu.history": "Balance history",
  "menu.settings": "Account settings",
  "menu.apikey": "Your Api-key: ",
  "menu.apikey_copy": "API-key copied",
  "language.ru": "Russian",
  "partners.common.username": "Name",
  "language.en": "English",
  profile: "Profile",
  "heading.subAccounts": "Accounts",
  "heading.personal": "Personal data",
  "placeholder.email": "E-mail",
  "placeholder.url": "Url",
  "placeholder.username": "Name",
  "placeholder.balance_threshold": "Balance threshold, RUB",
  "placeholder.os_type": "OS Type",
  "placeholder.description": "Description",
  "placeholder.scanning_port_threshold": "Scanning port threshold",
  "placeholder.delayed_scanning_port": "Delayed scanning port",
  "placeholder.period_clear_log": "Log cleanup period",
  "placeholder.port_prefix": "Port prefix",
  "placeholder.active_until": "License validity period",
  "placeholder.is_extended_log": "Extended log",
  "placeholder.is_enabled": "Is enabled",
  "placeholder.image": "Image",
  "modal.cancel": "Cancel",
  "modal.create": "Create",
  "confirm.agree": "Confirm your Action",
  "confirm.update_profile":
    "Are you sure you want to update your profile information?",
  "confirm.delete_rodos": "Are you sure you want to delete this Rodos?",
  "confirm.delete_iccid": "Are you sure you want to delete this ICCID?",
  "confirm.delete_license": "Are you sure you want to delete this license?",
  "form.update_data": "Update data",
  "confirm.confirm": "Confirm",
  "confirm.cancel": "Cancel",
  "login.title": "Login to the system",
  "login.subtitle": "Log in to your account",
  simbanks: "Simbanks",
  "simbanks.item.title": "Simbank",
  "simbanks.item.update": "Update all sims",
  "simbanks.partner": "Partner",
  "simbanks.port": "Port",
  "simbanks.id": "ID",
  "simbanks.model": "Model",
  "simbanks.number": "№",
  "simbanks.license": "License",
  "simbanks.status.online": "Online",
  "simbanks.status.offline": "Offline",
  "simbanks.status.in_banks": "In simbank",
  "simbanks.status.search": "Network search ",
  "simbanks.status.no_network": "No network",
  "simbanks.status.error": "Receive error Iccid",
  "iccid.title": "ICCID - bindings",
  "iccid.prefix": "Prefix",
  "common.actions": "Actions",
  "iccid.operator": "Operator",
  "common.gb": "Gb",
  "iccid.ussd": "USSD",
  "iccid.ussd_new": "USSD for new SIM",
  "iccid.country": "Country",
  "iccid.forwarding": "Forwarding",
  "placeholder.prefix": "Prefix",
  "placeholder.actions": "Actions",
  "placeholder.operator": "Operator",
  "placeholder.getnum_ussd": "USSD",
  "placeholder.newsim_ussd": "USSD for new SIM",
  "placeholder.country_id": "Country",
  "placeholder.forwardable": "Forwarding",
  "iccid.add": "Add ICCID",
  "iccid.edit": "Edit ICCID",
  "rodos.title": "Rodos connection settings",
  "heading.services": "Services",
  "rodos.header.title": "Rodos",
  "rodos.configuration": "Rodos config",
  "rodos.add": "Add Rodos",
  "rodos.add.modal.title":
    "Конфигурация каналов, \n для удаления привязки введите '-' в поле устройства",
  "rodos.channel": "Channel",
  "rodos.device": "Device",
  "rodos.devices": "Devices",
  "rodos.url": "Access url",
  "nav.main": "Main",
  "nav.about": "About",
  "nav.work": "How it works?",
  "nav.services": "Services",
  "nav.faq": "FAQ",
  "nav.api": "API",
  "nav.news": "News",
  "nav.profile": "Profile",
  "heading.tagline": "Your service for receiving SMS online",
  "heading.payments": "Payments",
  "heading.order": "Your orders",
  "heading.add_pay": "Replenishment of the balance",
  "heading.subtitle":
    "SMS-online.pro — is a fully automated service for receiving SMS messages online \nThis means that you will not need a mobile phone to confirm actions, activate accounts in popular services \nYou refill the account number and get the text of the SMS message on the screen. \nOur sms activation site also offers you to rent your personal virtual phone number. \nOn your choice - more than 5000 numbers from 15 countries of the world",
  "about.text":
    "A virtual phone number online is the ability to receive messages regardless of where you are in the world. The main thing is access to the network.\nTo perform all the necessary actions on the network, you do not need a real mobile phone - the service functions regardless of whether you are in the coverage area of the mobile network or not.\nAll that is required of you is to replenish the account of your virtual number. You do not need special equipment and a sim card.",
  "about.subtitle": "Why do I need a virtual phone?",
  "about.list1":
    "If you make regular mailings, then you just need to have dozens of telephone numbers, so that the message is guaranteed to reach the subscriber.",
  "about.list2":
    "Confirm registration on social networks and other services - now you can register multiple accounts on any site that you can use for your own purposes.",
  "about.list3":
    "  You can use your virtual number to protect against spam of the main phone when registering on suspicious resources.",
  "about.subtext":
    "The main advantage of a virtual phone can be considered cheap. It turns out that you have a phone that you can use for different purposes - an unobstructed flight of your imagination is guaranteed.\n\nYour personal phone number online is a guarantee that you will always be in touch! There are situations when a person lost a phone, forgot to pay for it, accidentally left home when he was packing things up for a trip.\nTogether with us - this is no longer a problem! All you need is access to the Internet.",
  "work.subtitle":
    "Receive SMS messages to a virtual number from any available services",
  "work.text1": "Register an account on our system",
  "work.text2": "Refill your balance",
  "work.text3": "Get SMS from any service",
  "services.subtitle": "Services from which we receive SMS at the moment",
  "table.service": "Service",
  "table.date": "Date",
  "filter.datepicker": "Select date",
  "table.status": "Status",
  "table.amount": "Amount",
  "table.type": "Type",
  "text.minCount": "Min. amount",
  "text.maxCount": "Max. amount",
  "text.alotof": "A lot",
  "table.count": "Count",
  "table.price": "Price",
  "table.country": "Country",
  "table.all": "All",
  "table.not_found": "Not found",
  "table.statuses": "Status",
  "table.waiting": "Waiting",
  "table.approved": "Approved",
  "table.declined": "Declined",
  "table.canceled": "Cancelled",
  "table.order_create": "Order successfully created",
  "table.refill": "Refill",
  "table.balance_correction": "Balance correction",
  "table.waiting_phone": "Waiting phone",
  "table.waiting_sms": "Waiting SMS",
  "table.finished": "Finished",
  "table.dateFrom": "Date from (DD.MM.YYYY)",
  "table.dateTo": "Date to (DD.MM.YYYY)",
  "table.notFound": "No matching results",
  "world.europe": "Europe",
  "world.asia": "Asia",
  "world.africa": "Africa",
  "world.south_america": "South America",
  "world.north_america": "North America",
  "world.oceania": "Oceania",
  "world.choose": "Select country",
  "country.om": "oman",
  "country.tg": "togo",
  "country.pf": "french polynesia",
  "country.af": "afghanistan",
  "country.tf": "french southern territories",
  "country.na": "namibia",
  "country.tk": "tokelau",
  "country.dj": "djibouti",
  "country.al": "albania",
  "country.nr": "nauru",
  "country.to": "tonga",
  "country.aq": "antarctica",
  "country.ga": "gabon",
  "country.dz": "algeria",
  "country.ge": "georgia",
  "country.np": "nepal",
  "country.tt": "trinidad and tobago",
  "country.gm": "gambia",
  "country.as": "eastern samoa",
  "country.nl": "netherlands",
  "country.ae": "united arab emirates",
  "country.an": "netherlands antilles",
  "country.ps": "state of palestine",
  "country.ad": "andorra",
  "country.de": "germany",
  "country.tn": "tunisia",
  "country.aw": "aruba",
  "country.ao": "angola",
  "country.tr": "turkey",
  "country.tm": "turkmenistan",
  "country.ag": "antigua and barbuda",
  "country.nc": "new caledonia",
  "country.tc": "turks and caicos islands",
  "country.tv": "tuvalu",
  "country.az": "azerbaijan",
  "country.ar": "argentina",
  "country.gh": "ghana",
  "country.ug": "uganda",
  "country.au": "australia",
  "country.gi": "gibraltar",
  "country.vu": "vanuatu",
  "country.ua": "ukraine",
  "country.mk": "republic of north macedonia",
  "country.at": "austria",
  "country.ki": "kiribati",
  "country.nz": "new zealand",
  "country.bs": "bahamas",
  "country.gr": "greece",
  "country.ni": "nicaragua",
  "country.bh": "bahrain",
  "country.gl": "greenland",
  "country.bd": "bangladesh",
  "country.ne": "niger",
  "country.eg": "egypt",
  "country.am": "armenia",
  "country.bb": "barbados",
  "country.gd": "grenada",
  "country.ng": "nigeria",
  "country.be": "belgium",
  "country.gp": "guadeloupe",
  "country.nu": "niue",
  "country.gb": "united kingdom of great britain and northern ireland",
  "country.bm": "bermuda",
  "country.gu": "guam",
  "country.nf": "norfolk island",
  "country.gg": "guernsey",
  "country.bt": "bhutan",
  "country.gt": "guatemala",
  "country.je": "jersey",
  "country.im": "isle of man",
  "country.no": "norway",
  "country.tz": "tanzania",
  "country.bo": "bolivia",
  "country.gn": "guinea",
  "country.mp": "northern mariana islands",
  "country.um": "united states minor outlying islands",
  "country.ba": "bosnia and herzegovina",
  "country.fm": "micronesia",
  "country.bw": "botswana",
  "country.gy": "guyana",
  "country.mh": "marshall islands",
  "country.us": "united states of america",
  "country.pw": "palau",
  "country.bv": "bouvet island",
  "country.pk": "pakistan",
  "country.br": "brazil",
  "country.ht": "haiti",
  "country.hm": "heard island and mcdonald islands",
  "country.pa": "panama",
  "country.va": "holy see",
  "country.vi": "virgin islands",
  "country.bz": "belize",
  "country.hn": "honduras",
  "country.io": "british territories",
  "country.pg": "papua new guinea",
  "country.bf": "burkina faso",
  "country.hk": "hong kong",
  "country.py": "paraguay",
  "country.sb": "solomon islands",
  "country.uy": "uruguay",
  "country.vg": "virgin islands, british",
  "country.hu": "hungary",
  "country.pe": "peru",
  "country.uz": "uzbekistan",
  "country.ve": "venezuela",
  "country.bn": "brunei",
  "country.is": "iceland",
  "country.ph": "philippines",
  "country.bg": "bulgaria",
  "country.in": "india",
  "country.pn": "pitcairn",
  "country.mm": "myanmar",
  "country.id": "indonesia",
  "country.pl": "poland",
  "country.bi": "burundi",
  "country.ir": "iran",
  "country.pt": "portugal",
  "country.wf": "wallis and futuna",
  "country.by": "belarus",
  "country.iq": "iraq",
  "country.gw": "guinea-bissau",
  "country.tp": "east timor",
  "country.ws": "samoa",
  "country.kh": "cambodia",
  "country.ie": "ireland",
  "country.pr": "puerto rico",
  "country.ye": "yemen",
  "country.cm": "cameroon",
  "country.il": "israel",
  "country.qa": "qatar",
  "country.ca": "canada",
  "country.it": "italy",
  "country.re": "réunion",
  "country.zm": "zambia",
  "country.tl": "timor-leste",
  "country.ci": "côte d'ivoire",
  "country.ro": "romania",
  "country.ru": "russian federation",
  "country.cv": "cape verde",
  "country.jm": "jamaica",
  "country.rw": "rwanda",
  "country.ky": "cayman islands",
  "country.jp": "japan",
  "country.cf": "central african republic",
  "country.kz": "kazakhstan",
  "country.sh": "saint helena",
  "country.lk": "sri lanka",
  "country.jo": "jordan",
  "country.kn": "saint kitts and nevis",
  "country.td": "chad",
  "country.ke": "kenya",
  "country.ai": "anguilla",
  "country.lc": "saint lucia",
  "country.cl": "chile",
  "country.kp": "democratic people's republic of korea",
  "country.kr": "korea",
  "country.pm": "saint pierre and miquelon",
  "country.cn": "china",
  "country.tw": "taiwan",
  "country.kw": "kuwait",
  "country.vc": "saint vincent and the grenadines",
  "country.kg": "kyrgyzstan",
  "country.cx": "christmas islands",
  "country.la": "lao people's democratic republic",
  "country.sm": "san marino",
  "country.cc": "cocos islands",
  "country.lb": "lebanon",
  "country.st": "sao tome and principe",
  "country.co": "colombia",
  "country.ls": "lesotho",
  "country.sa": "saudi arabia",
  "country.lv": "latvia",
  "country.km": "comoros",
  "country.lr": "liberia",
  "country.sn": "senegal",
  "country.yt": "mayotte",
  "country.rs": "serbia",
  "country.cg": "congo",
  "country.ly": "libya",
  "country.sc": "seychelles",
  "country.cd": "democratic republic of the congo",
  "country.li": "liechtenstein",
  "country.sl": "sierra leone",
  "country.ck": "cook islands",
  "country.lt": "lithuania",
  "country.lu": "luxembourg",
  "country.cr": "costa rica",
  "country.mo": "macao",
  "country.sg": "singapore",
  "country.hr": "croatia",
  "country.sk": "slovakia",
  "country.cu": "cuba",
  "country.vn": "viet nam",
  "country.si": "slovenia",
  "country.mg": "madagascar",
  "country.so": "somalia",
  "country.cy": "cyprus",
  "country.mw": "malawi",
  "country.za": "south africa",
  "country.my": "malaysia",
  "country.cz": "czechia",
  "country.bj": "benin",
  "country.zw": "zimbabwe",
  "country.mv": "maldives",
  "country.dk": "denmark",
  "country.ml": "mali",
  "country.dm": "dominica",
  "country.es": "spain",
  "country.do": "dominican republic",
  "country.mt": "malta",
  "country.ec": "ecuador",
  "country.mq": "martinique",
  "country.eh": "western sahara",
  "country.sv": "el salvador",
  "country.mr": "mauritania",
  "country.mu": "mauritius",
  "country.sd": "sudan",
  "country.gq": "equatorial guinea",
  "country.mx": "mexico",
  "country.sr": "suriname",
  "country.et": "ethiopia",
  "country.er": "eritrea",
  "country.sj": "svalbard and jan mayen",
  "country.ee": "estonia",
  "country.fo": "faroe islands",
  "country.mc": "monaco",
  "country.sz": "eswatini",
  "country.fk": "falkland islands",
  "country.gs": "south georgia and the south sandwich islands",
  "country.mn": "mongolia",
  "country.se": "sweden",
  "country.fj": "fiji",
  "country.md": "moldova",
  "country.me": "montenegro",
  "country.ms": "montserrat",
  "country.ch": "switzerland",
  "country.fi": "finland",
  "country.ax": "åland islands",
  "country.ma": "morocco",
  "country.sy": "syrian arab republic",
  "country.fr": "france",
  "country.tj": "tajikistan",
  "country.mz": "mozambique",
  "country.th": "thailand",
  "country.gf": "french guiana",
  "country.xy": "Random country",
  "country.search": "Search by country",
};
