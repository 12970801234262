import React, { useState, useEffect } from "react";
import "./index.scss";
import Aside from "components/Sidebar";

import {
  Content,
  Container,
  Heading,
  Loader,
  Pagination,
  Table,
  Text,
} from "rsuite";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { List } from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import PlusIcon from "@rsuite/icons/Plus";
import { IconButton } from "rsuite";
import AddSmsModal from "./addModal";
import EditIcon from "@rsuite/icons/Edit";

function SmsFormatPage() {
  const [list, setList] = useState(null);
  const [providers, setProviders] = useState(null);
  const [services, setServices] = useState(null);
  const [countries, setCountries] = useState(null);
  const { Column, HeaderCell, Cell } = Table;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("add");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [defaultData, setDefaultData] = useState({
    id: null,
    regex: "",
    service: null,
    country: null,
    provider: null,
    contentExample: "",
  });
  const getList = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/sms-format-settings`)
      .then(function (response) {
        if (response.data.data?.length > 0) {
          setList(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getProviders = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/provider/list`)
      .then(function (response) {
        if (response.data.providerList?.length > 0) {
          setProviders(response.data.providerList);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getCountries = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/country/list`)
      .then(function (response) {
        if (response.data.countryList?.length > 0) {
          setCountries(response.data.countryList);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!open) {
      setDefaultData({
        id: null,
        regex: "",
        service: null,
        country: null,
        provider: null,
        contentExample: "",
      });
    }
  }, [open]);
  const getServices = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/service/list`)
      .then(function (response) {
        if (response.data.serviceList?.length > 0) {
          setServices(response.data.serviceList);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getList();
    getCountries();
    getProviders();
    getServices();
  }, []);
  const handleProvider = (val, uid) => {
    setLoading(true);
    Axios.put(`${API_URL}/admin/provider/enabled`, {
      providerUid: uid,
      enabled: val,
    })
      .then(function (response) {
        getList();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const listData = list?.filter((v, i) => {
    const start = perPage * (page - 1);
    const end = start + perPage;
    return i >= start && i < end;
  });
  const CountryCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <div className="country-block">
          <div class={`flag flag-${rowData.country}`} />
          <span>{t(`country.${rowData.country}`)}</span>
        </div>
      </Cell>
    );
  };
  const ServiceCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <div
          className="service-block"
          style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
        >
          <div class={`bg bg-${rowData.service}`} />
          <span>
            {services?.filter((el) => rowData.service == el.uid)?.[0]?.name}
          </span>
        </div>
      </Cell>
    );
  };
  const EditCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <IconButton
          color="blue"
          appearance="ghost"
          onClick={() => {
            setType("edit");
            setDefaultData({
              id: rowData.id,
              regex: rowData.regex,
              service: rowData.service,
              country: rowData.country,
              provider: rowData.provider,
              contentExample: rowData.contentExample,
            });
            setTimeout(() => setOpen(true), 50);
          }}
          icon={<EditIcon />}
          size="sm"
        />
      </Cell>
    );
  };
  const RegexCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <Text as="kbd" style={{ display: "flex", alignItems: "center" }}>
          {rowData.regex}{" "}
        </Text>
      </Cell>
    );
  };

  return (
    <>
      <Container>
        <Aside />
        <Content>
          {loading && <Loader center backgrop size="lg" />}

          <div className="box-block">
            <Heading level={4} className="d-flex justify-content-between">
              {t("menu.smsFormat")}{" "}
              <IconButton
                color="blue"
                appearance="primary"
                onClick={() => {
                  setType('add')
                  setOpen(true);
                }}
                icon={<PlusIcon />}
              />
            </Heading>
            {listData?.length > 0 && (
              <Table data={listData} autoHeight>
                <Column align="start">
                  <HeaderCell>{t(`common.id`)}</HeaderCell>
                  <Cell dataKey="id" />
                </Column>
                <Column align="start">
                  <HeaderCell>{t(`table.regex`)}</HeaderCell>
                  <RegexCell dataKey="regex" />
                </Column>
                <Column align="start" flexGrow={1}>
                  <HeaderCell>{t(`table.provider`)}</HeaderCell>
                  <Cell dataKey="provider" />
                </Column>
                <Column align="start" flexGrow={1}>
                  <HeaderCell>{t(`table.service`)}</HeaderCell>
                  <ServiceCell dataKey="service" />
                </Column>
                <Column align="start" flexGrow={1}>
                  <HeaderCell>{t(`table.country`)}</HeaderCell>
                  <CountryCell dataKey="country" />
                </Column>
                <Column align="start" flexGrow={1}>
                  <HeaderCell>{t(`table.contentExample`)}</HeaderCell>
                  <Cell dataKey="contentExample" />
                </Column>
                <Column align="start" flexGrow={1} width="50px">
                  <HeaderCell>{t(`table.edit`)}</HeaderCell>
                  <EditCell dataKey="edit" />
                </Column>
                {/* <Column align="start" flexGrow={1} >
                  <HeaderCell>{t(`table.total`)}</HeaderCell>
                  <Cell dataKey="total" />
                </Column>
                <Column align="start" flexGrow={1} >
                  <HeaderCell>{t(`table.finished`)}</HeaderCell>
                  <Cell dataKey="finished" />
                </Column> */}
              </Table>
            )}
          </div>
        </Content>
      </Container>
      {open && services?.length > 0 &&
        countries?.length > 0 &&
        providers?.length > 0 && (
          <AddSmsModal
            open={open}
            setOpen={setOpen}
            providers={providers}
            countries={countries}
            services={services}
            callback={() => getList()}
            type={type}
            defaultValue={defaultData}
          />
        )}
    </>
  );
}

export default SmsFormatPage;
